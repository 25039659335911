<template>
  <v-main class="mt-16">
    <div class="container mt-16">
      <ul class="v-breadcrumbs ps-0 theme--light">
        <li><a href="/" class="v-breadcrumbs__item">Home</a></li>
        <li class="v-breadcrumbs__divider">
          <i
            aria-hidden="true"
            class="v-icon notranslate mdi mdi-chevron-right theme--light"
          ></i>
        </li>
        <li><a href="/" class="v-breadcrumbs__item">Ulaanbaatar</a></li>
        <li class="v-breadcrumbs__divider">
          <i
            aria-hidden="true"
            class="v-icon notranslate mdi mdi-chevron-right theme--light"
          ></i>
        </li>
        <li>
          <a href="/" class="v-breadcrumbs__item v-breadcrumbs__item--disabled"
            >Restaraunts</a
          >
        </li>
      </ul>
      <div class="row row--dense">
        <div class="col-lg-6 col-xl-6 col-12">
          <div
            class="v-image v-responsive theme--light"
            style="height: 100%; width: 100%"
          >
            <div
              class="v-responsive__sizer"
              style="padding-bottom: 54.9928%"
            ></div>
            <div
              class="v-image__image v-image__image--cover"
              style="
                background-image: url('https://foodhub-nuxt.vercel.app/_nuxt/img/bigFood.fb87b8d.png');
                background-position: center center;
              "
            ></div>
            <div class="v-responsive__content" style="width: 691px"></div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-6 col-12">
          <div class="row row--dense">
            <div class="col-xl-6 col-6">
              <div class="v-image v-responsive theme--light">
                <div
                  class="v-responsive__sizer"
                  style="padding-bottom: 74.8988%"
                ></div>
                <div
                  class="v-image__image v-image__image--contain"
                  style="
                    background-image: url('https://foodhub-nuxt.vercel.app/_nuxt/img/foodOne.f29cb9f.png');
                    background-position: center center;
                  "
                ></div>
                <div class="v-responsive__content" style="width: 247px"></div>
              </div>
            </div>
            <div class="col-xl-6 col-6">
              <div class="v-image v-responsive theme--light">
                <div
                  class="v-responsive__sizer"
                  style="padding-bottom: 74.8988%"
                ></div>
                <div
                  class="v-image__image v-image__image--contain"
                  style="
                    background-image: url('https://foodhub-nuxt.vercel.app/_nuxt/img/foodTwo.3b74f91.png');
                    background-position: center center;
                  "
                ></div>
                <div class="v-responsive__content" style="width: 247px"></div>
              </div>
            </div>
            <div class="col-xl-6 col-6">
              <div class="v-image v-responsive theme--light">
                <div
                  class="v-responsive__sizer"
                  style="padding-bottom: 74.8988%"
                ></div>
                <div
                  class="v-image__image v-image__image--contain"
                  style="
                    background-image: url('https://foodhub-nuxt.vercel.app/_nuxt/img/foodThree.fd71db5.png');
                    background-position: center center;
                  "
                ></div>
                <div class="v-responsive__content" style="width: 247px"></div>
              </div>
            </div>
            <div class="col-xl-6 col-6">
              <div class="v-image v-responsive theme--light">
                <div
                  class="v-responsive__sizer"
                  style="padding-bottom: 74.8988%"
                ></div>
                <div
                  class="v-image__image v-image__image--contain"
                  style="
                    background-image: url('https://foodhub-nuxt.vercel.app/_nuxt/img/foodFour.e242457.png');
                    background-position: center center;
                  "
                ></div>
                <div class="v-responsive__content" style="width: 247px"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 col col-12">
          <div class="d-flex justify-space-between flex-wrap align-center mb-3">
            <h1 class="me-2">Nomads</h1>
            <div class="mb-3">
              <span
                ><i
                  aria-hidden="true"
                  class="
                    v-icon
                    notranslate
                    mdi mdi-star
                    theme--light
                    warning--text
                  "
                  style="font-size: 16px"
                ></i></span
              ><span
                ><i
                  aria-hidden="true"
                  class="
                    v-icon
                    notranslate
                    mdi mdi-star
                    theme--light
                    warning--text
                  "
                  style="font-size: 16px"
                ></i></span
              ><span
                ><i
                  aria-hidden="true"
                  class="
                    v-icon
                    notranslate
                    mdi mdi-star
                    theme--light
                    warning--text
                  "
                  style="font-size: 16px"
                ></i></span
              ><span
                ><i
                  aria-hidden="true"
                  class="
                    v-icon
                    notranslate
                    mdi mdi-star
                    theme--light
                    warning--text
                  "
                  style="font-size: 16px"
                ></i></span
              ><span
                ><i
                  aria-hidden="true"
                  class="
                    v-icon
                    notranslate
                    mdi mdi-star
                    theme--light
                    warning--text
                  "
                  style="font-size: 16px"
                ></i
              ></span>
              <span class="text-14 me-1">
                4.5 <span class="grey--text">(1004)</span></span
              >
            </div>
          </div>
          <p class="mb-5 text-14">
            Coffee, signature roasts, light bite, quick bite, fast foods
          </p>
          <div
            class="
              grey--text
              text--darken-1
              align-middle
              text-14
              mb-4
              d-flex
              align-center
              flex-wrap
            "
          >
            <i
              aria-hidden="true"
              class="
                v-icon
                notranslate
                v-icon--left
                mdi mdi-map-marker
                theme--light
                grey--text
              "
              style="font-size: 16px"
            ></i>
            The Baga-Toiruu, Ulaanbaatar -
            <a
              href="/resturant/FoodMenu"
              aria-current="page"
              class="
                grey--text
                text--darken-3
                nuxt-link-exact-active nuxt-link-active
              "
              >Show Map</a
            >
          </div>
          <div
            class="
              grey--text
              text--darken-1
              align-middle
              text-14
              mb-4
              d-flex
              align-center
              flex-wrap
            "
          >
            <i
              aria-hidden="true"
              class="
                v-icon
                notranslate
                v-icon--left
                mdi mdi-clock-outline
                theme--light
                grey--text
              "
              style="font-size: 16px"
            ></i>
            <span class="primary--text me-2">Open Now</span> - Sun - Mon: 9am -
            11pm
          </div>
          <div
            class="
              grey--text
              text--darken-1
              align-middle
              text-14
              mb-4
              d-flex
              align-center
              flex-wrap
            "
          >
            <i
              aria-hidden="true"
              class="
                v-icon
                notranslate
                v-icon--left
                mdi mdi-earth
                theme--light
                grey--text
              "
              style="font-size: 16px"
            ></i>
            <a
              href="/resturant/FoodMenu"
              aria-current="page"
              class="
                text-decoration-none
                grey--text
                text--darken-1
                nuxt-link-exact-active nuxt-link-active
              "
              >www.nomads.com</a
            >
          </div>
          <v-tabs color="red" v-model="tab">
            <v-tab v-for="item in tabs" :key="item">
              {{ item }}
            </v-tab>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-row>
                  <v-col cols="6">
                    <v-row class="mt-6">
                      <v-col cols="6">
                        <v-dialog
                          ref="dialog"
                          v-model="modal"
                          :return-value.sync="date"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              outlined
                              dense
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          :items="guestsValue"
                          v-model="guests"
                          label="Number Of Guests"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n6">
                      <v-col cols="6">
                        <v-text-field
                          label="First Name"
                          v-model="firstName"
                          outlined
                          dense
                          @keyup.enter.prevent="addItem"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Last Name"
                          v-model="lastName"
                          outlined
                          dense
                          @keyup.enter.prevent="addItem"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n6">
                      <v-col cols="6">
                        <v-text-field
                          label="Email"
                          v-model="email"
                          outlined
                          dense
                          @keyup.enter.prevent="addItem"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Phone"
                          v-model="phone"
                          outlined
                          dense
                          @keyup.enter.prevent="addItem"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-btn color="red" class="white--text" @click="addItem"
                      >Book</v-btn
                    >
                  </v-col>
                  <v-col cols="6"></v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <div class="d-flex flex-row">
                  <v-col cols="3">
                    <v-select
                      label="Rating"
                      :items="ratingValue"
                      v-model="rating"
                      outlined
                      dense
                      @keyup.enter.prevent="addRating"
                    ></v-select>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn color="yellow" class="mt-n2" @click="addRating">
                      Rate!
                    </v-btn>
                  </v-col>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
      </div>
    </div>
  </v-main>
</template>
<script>
import TripItem from "./TripItem.vue";
import { db, updateRating } from "@/firebaseConfig.js";
export default {
  components: {
    TripItem,
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      tab: null,
      tabs: ["Book a Table", "Reviews"],
      items: Array.from(
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        function mapFn(element) {
          return { title: element, subtitle: element, headline: element };
        }
      ),
      guestsValue: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      ratingValue: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
      ratings: [],
      labels: ["all", "notcompleted", "completed", "running"],
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      guests: null,
      rating: null,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  props: {
    restaurantId: {
      required: false,
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async addItem() {
      if (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.phone &&
        this.guests
      ) {
        await db
          .collection("restaurant/" + this.restaurantId + "/description/")
          .add({
            description: {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              phone: this.phone,
              guests: this.guests,
            },
          })
          .then(() => {
            alert("Succesfully Booked!");
            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.phone = null;
            this.guests = null;
          });
      }
    },
    async addRating() {
      const work = db.collection("restaurant");
      if (this.rating) {
        work.rating = this.rating;
        await updateRating(this.restaurantId, { ...work });
      }
    },
  },
};
</script>